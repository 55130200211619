/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Query Service
 * Query Service Api Documentation
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CatalogGroupDetailArray } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { CatalogGroupDetailWithSequenceArray } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CategoryViewResourceApi - axios parameter creator
 * @export
 */
export const CategoryViewResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets category details based on a list of unique IDs assigned to the categories by the database.
         * @param {string} storeId 
         * @param {Array<string>} id 
         * @param {string} [catalogId] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategoriesByUniqueIds: async (storeId: string, id: Array<string>, catalogId?: string, orderBy?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findCategoriesByUniqueIds', 'storeId', storeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findCategoriesByUniqueIds', 'id', id)
            const localVarPath = `/store/{storeId}/categoryview/byIds`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets category details based on its identifier (not the ID assigned by the database).
         * @param {string} storeId 
         * @param {string} categoryIdentifier 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategoryByIdentifier: async (storeId: string, categoryIdentifier: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findCategoryByIdentifier', 'storeId', storeId)
            // verify required parameter 'categoryIdentifier' is not null or undefined
            assertParamExists('findCategoryByIdentifier', 'categoryIdentifier', categoryIdentifier)
            const localVarPath = `/store/{storeId}/categoryview/{categoryIdentifier}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"categoryIdentifier"}}`, encodeURIComponent(String(categoryIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets category details based on category identifiers specified (not the id assigned by the database).
         * @param {string} storeId 
         * @param {Array<string>} [identifier] 
         * @param {string} [catalogId] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategoryByIdentifiers: async (storeId: string, identifier?: Array<string>, catalogId?: string, orderBy?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findCategoryByIdentifiers', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/categoryview/byIdentifiers`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (identifier) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets category details based on the unique ID assigned to the category by the database.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {string} [contractId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategoryByUniqueId: async (storeId: string, categoryId: string, catalogId?: string, currency?: string, langId?: string, contractId?: string, checkEntitlement?: boolean, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findCategoryByUniqueId', 'storeId', storeId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('findCategoryByUniqueId', 'categoryId', categoryId)
            const localVarPath = `/store/{storeId}/categoryview/byId/{categoryId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets child categories based on the unique Id assigned to the parent category by the database.
         * @param {string} storeId 
         * @param {string} parentCategoryId 
         * @param {string} [depthAndLimit] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubCategories: async (storeId: string, parentCategoryId: string, depthAndLimit?: string, orderBy?: string, contractId?: string, catalogId?: string, currency?: string, langId?: string, pageSize?: number, pageNumber?: number, checkEntitlement?: boolean, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findSubCategories', 'storeId', storeId)
            // verify required parameter 'parentCategoryId' is not null or undefined
            assertParamExists('findSubCategories', 'parentCategoryId', parentCategoryId)
            const localVarPath = `/store/{storeId}/categoryview/byParentCategory/{parentCategoryId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"parentCategoryId"}}`, encodeURIComponent(String(parentCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (depthAndLimit !== undefined) {
                localVarQueryParameter['depthAndLimit'] = depthAndLimit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all top level categories.
         * @param {string} storeId 
         * @param {string} [depthAndLimit] 
         * @param {string} [orderBy] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTopCategories: async (storeId: string, depthAndLimit?: string, orderBy?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, pageSize?: number, pageNumber?: number, checkEntitlement?: boolean, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findTopCategories', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/categoryview/@top`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (depthAndLimit !== undefined) {
                localVarQueryParameter['depthAndLimit'] = depthAndLimit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryViewResourceApi - functional programming interface
 * @export
 */
export const CategoryViewResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryViewResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets category details based on a list of unique IDs assigned to the categories by the database.
         * @param {string} storeId 
         * @param {Array<string>} id 
         * @param {string} [catalogId] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCategoriesByUniqueIds(storeId: string, id: Array<string>, catalogId?: string, orderBy?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogGroupDetailArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCategoriesByUniqueIds(storeId, id, catalogId, orderBy, contractId, currency, langId, checkEntitlement, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets category details based on its identifier (not the ID assigned by the database).
         * @param {string} storeId 
         * @param {string} categoryIdentifier 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCategoryByIdentifier(storeId: string, categoryIdentifier: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogGroupDetailArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCategoryByIdentifier(storeId, categoryIdentifier, catalogId, contractId, currency, langId, checkEntitlement, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets category details based on category identifiers specified (not the id assigned by the database).
         * @param {string} storeId 
         * @param {Array<string>} [identifier] 
         * @param {string} [catalogId] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCategoryByIdentifiers(storeId: string, identifier?: Array<string>, catalogId?: string, orderBy?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogGroupDetailArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCategoryByIdentifiers(storeId, identifier, catalogId, orderBy, contractId, currency, langId, checkEntitlement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets category details based on the unique ID assigned to the category by the database.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {string} [contractId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCategoryByUniqueId(storeId: string, categoryId: string, catalogId?: string, currency?: string, langId?: string, contractId?: string, checkEntitlement?: boolean, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogGroupDetailArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCategoryByUniqueId(storeId, categoryId, catalogId, currency, langId, contractId, checkEntitlement, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets child categories based on the unique Id assigned to the parent category by the database.
         * @param {string} storeId 
         * @param {string} parentCategoryId 
         * @param {string} [depthAndLimit] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSubCategories(storeId: string, parentCategoryId: string, depthAndLimit?: string, orderBy?: string, contractId?: string, catalogId?: string, currency?: string, langId?: string, pageSize?: number, pageNumber?: number, checkEntitlement?: boolean, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogGroupDetailWithSequenceArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSubCategories(storeId, parentCategoryId, depthAndLimit, orderBy, contractId, catalogId, currency, langId, pageSize, pageNumber, checkEntitlement, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all top level categories.
         * @param {string} storeId 
         * @param {string} [depthAndLimit] 
         * @param {string} [orderBy] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTopCategories(storeId: string, depthAndLimit?: string, orderBy?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, pageSize?: number, pageNumber?: number, checkEntitlement?: boolean, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogGroupDetailWithSequenceArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTopCategories(storeId, depthAndLimit, orderBy, catalogId, contractId, currency, langId, pageSize, pageNumber, checkEntitlement, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryViewResourceApi - factory interface
 * @export
 */
export const CategoryViewResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryViewResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets category details based on a list of unique IDs assigned to the categories by the database.
         * @param {string} storeId 
         * @param {Array<string>} id 
         * @param {string} [catalogId] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategoriesByUniqueIds(storeId: string, id: Array<string>, catalogId?: string, orderBy?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, profileName?: string, options?: any): AxiosPromise<CatalogGroupDetailArray> {
            return localVarFp.findCategoriesByUniqueIds(storeId, id, catalogId, orderBy, contractId, currency, langId, checkEntitlement, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets category details based on its identifier (not the ID assigned by the database).
         * @param {string} storeId 
         * @param {string} categoryIdentifier 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategoryByIdentifier(storeId: string, categoryIdentifier: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, profileName?: string, options?: any): AxiosPromise<CatalogGroupDetailArray> {
            return localVarFp.findCategoryByIdentifier(storeId, categoryIdentifier, catalogId, contractId, currency, langId, checkEntitlement, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets category details based on category identifiers specified (not the id assigned by the database).
         * @param {string} storeId 
         * @param {Array<string>} [identifier] 
         * @param {string} [catalogId] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategoryByIdentifiers(storeId: string, identifier?: Array<string>, catalogId?: string, orderBy?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, options?: any): AxiosPromise<CatalogGroupDetailArray> {
            return localVarFp.findCategoryByIdentifiers(storeId, identifier, catalogId, orderBy, contractId, currency, langId, checkEntitlement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets category details based on the unique ID assigned to the category by the database.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {string} [contractId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategoryByUniqueId(storeId: string, categoryId: string, catalogId?: string, currency?: string, langId?: string, contractId?: string, checkEntitlement?: boolean, profileName?: string, options?: any): AxiosPromise<CatalogGroupDetailArray> {
            return localVarFp.findCategoryByUniqueId(storeId, categoryId, catalogId, currency, langId, contractId, checkEntitlement, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets child categories based on the unique Id assigned to the parent category by the database.
         * @param {string} storeId 
         * @param {string} parentCategoryId 
         * @param {string} [depthAndLimit] 
         * @param {string} [orderBy] 
         * @param {string} [contractId] 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubCategories(storeId: string, parentCategoryId: string, depthAndLimit?: string, orderBy?: string, contractId?: string, catalogId?: string, currency?: string, langId?: string, pageSize?: number, pageNumber?: number, checkEntitlement?: boolean, profileName?: string, options?: any): AxiosPromise<CatalogGroupDetailWithSequenceArray> {
            return localVarFp.findSubCategories(storeId, parentCategoryId, depthAndLimit, orderBy, contractId, catalogId, currency, langId, pageSize, pageNumber, checkEntitlement, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all top level categories.
         * @param {string} storeId 
         * @param {string} [depthAndLimit] 
         * @param {string} [orderBy] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTopCategories(storeId: string, depthAndLimit?: string, orderBy?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, pageSize?: number, pageNumber?: number, checkEntitlement?: boolean, profileName?: string, options?: any): AxiosPromise<CatalogGroupDetailWithSequenceArray> {
            return localVarFp.findTopCategories(storeId, depthAndLimit, orderBy, catalogId, contractId, currency, langId, pageSize, pageNumber, checkEntitlement, profileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryViewResourceApi - object-oriented interface
 * @export
 * @class CategoryViewResourceApi
 * @extends {BaseAPI}
 */
export class CategoryViewResourceApi extends BaseAPI {
    /**
     * 
     * @summary Gets category details based on a list of unique IDs assigned to the categories by the database.
     * @param {string} storeId 
     * @param {Array<string>} id 
     * @param {string} [catalogId] 
     * @param {string} [orderBy] 
     * @param {string} [contractId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryViewResourceApi
     */
    public findCategoriesByUniqueIds(storeId: string, id: Array<string>, catalogId?: string, orderBy?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, profileName?: string, options?: any) {
        return CategoryViewResourceApiFp(this.configuration).findCategoriesByUniqueIds(storeId, id, catalogId, orderBy, contractId, currency, langId, checkEntitlement, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets category details based on its identifier (not the ID assigned by the database).
     * @param {string} storeId 
     * @param {string} categoryIdentifier 
     * @param {string} [catalogId] 
     * @param {string} [contractId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryViewResourceApi
     */
    public findCategoryByIdentifier(storeId: string, categoryIdentifier: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, profileName?: string, options?: any) {
        return CategoryViewResourceApiFp(this.configuration).findCategoryByIdentifier(storeId, categoryIdentifier, catalogId, contractId, currency, langId, checkEntitlement, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets category details based on category identifiers specified (not the id assigned by the database).
     * @param {string} storeId 
     * @param {Array<string>} [identifier] 
     * @param {string} [catalogId] 
     * @param {string} [orderBy] 
     * @param {string} [contractId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {boolean} [checkEntitlement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryViewResourceApi
     */
    public findCategoryByIdentifiers(storeId: string, identifier?: Array<string>, catalogId?: string, orderBy?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, options?: any) {
        return CategoryViewResourceApiFp(this.configuration).findCategoryByIdentifiers(storeId, identifier, catalogId, orderBy, contractId, currency, langId, checkEntitlement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets category details based on the unique ID assigned to the category by the database.
     * @param {string} storeId 
     * @param {string} categoryId 
     * @param {string} [catalogId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {string} [contractId] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryViewResourceApi
     */
    public findCategoryByUniqueId(storeId: string, categoryId: string, catalogId?: string, currency?: string, langId?: string, contractId?: string, checkEntitlement?: boolean, profileName?: string, options?: any) {
        return CategoryViewResourceApiFp(this.configuration).findCategoryByUniqueId(storeId, categoryId, catalogId, currency, langId, contractId, checkEntitlement, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets child categories based on the unique Id assigned to the parent category by the database.
     * @param {string} storeId 
     * @param {string} parentCategoryId 
     * @param {string} [depthAndLimit] 
     * @param {string} [orderBy] 
     * @param {string} [contractId] 
     * @param {string} [catalogId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryViewResourceApi
     */
    public findSubCategories(storeId: string, parentCategoryId: string, depthAndLimit?: string, orderBy?: string, contractId?: string, catalogId?: string, currency?: string, langId?: string, pageSize?: number, pageNumber?: number, checkEntitlement?: boolean, profileName?: string, options?: any) {
        return CategoryViewResourceApiFp(this.configuration).findSubCategories(storeId, parentCategoryId, depthAndLimit, orderBy, contractId, catalogId, currency, langId, pageSize, pageNumber, checkEntitlement, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all top level categories.
     * @param {string} storeId 
     * @param {string} [depthAndLimit] 
     * @param {string} [orderBy] 
     * @param {string} [catalogId] 
     * @param {string} [contractId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryViewResourceApi
     */
    public findTopCategories(storeId: string, depthAndLimit?: string, orderBy?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, pageSize?: number, pageNumber?: number, checkEntitlement?: boolean, profileName?: string, options?: any) {
        return CategoryViewResourceApiFp(this.configuration).findTopCategories(storeId, depthAndLimit, orderBy, catalogId, contractId, currency, langId, pageSize, pageNumber, checkEntitlement, profileName, options).then((request) => request(this.axios, this.basePath));
    }
}
