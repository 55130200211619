/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Query Service
 * Query Service Api Documentation
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { SearchProfile } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * SearchProfileResourceApi - axios parameter creator
 * @export
 */
export const SearchProfileResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create the search profile.
         * @param {string} profileName 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSearchProfile: async (profileName: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('createSearchProfile', 'profileName', profileName)
            const localVarPath = `/api/v2/documents/profiles/{profileName}`
                .replace(`{${"profileName"}}`, encodeURIComponent(String(profileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the search profile.
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile: async (profileName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('deleteProfile', 'profileName', profileName)
            const localVarPath = `/api/v2/documents/profiles/{profileName}`
                .replace(`{${"profileName"}}`, encodeURIComponent(String(profileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the search profile.
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchProfile: async (profileName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('getSearchProfile', 'profileName', profileName)
            const localVarPath = `/api/v2/documents/profiles/{profileName}`
                .replace(`{${"profileName"}}`, encodeURIComponent(String(profileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the search profiles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchProfileList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/documents/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the search profile.
         * @param {string} profileName 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSearchProfile: async (profileName: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('updateSearchProfile', 'profileName', profileName)
            const localVarPath = `/api/v2/documents/profiles/{profileName}`
                .replace(`{${"profileName"}}`, encodeURIComponent(String(profileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchProfileResourceApi - functional programming interface
 * @export
 */
export const SearchProfileResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchProfileResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create the search profile.
         * @param {string} profileName 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSearchProfile(profileName: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSearchProfile(profileName, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the search profile.
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfile(profileName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfile(profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the search profile.
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchProfile(profileName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchProfile(profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the search profiles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchProfileList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchProfileList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the search profile.
         * @param {string} profileName 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSearchProfile(profileName: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSearchProfile(profileName, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchProfileResourceApi - factory interface
 * @export
 */
export const SearchProfileResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchProfileResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create the search profile.
         * @param {string} profileName 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSearchProfile(profileName: string, body?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.createSearchProfile(profileName, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the search profile.
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile(profileName: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deleteProfile(profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the search profile.
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchProfile(profileName: string, options?: any): AxiosPromise<Array<SearchProfile>> {
            return localVarFp.getSearchProfile(profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the search profiles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchProfileList(options?: any): AxiosPromise<Array<SearchProfile>> {
            return localVarFp.getSearchProfileList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the search profile.
         * @param {string} profileName 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSearchProfile(profileName: string, body?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.updateSearchProfile(profileName, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchProfileResourceApi - object-oriented interface
 * @export
 * @class SearchProfileResourceApi
 * @extends {BaseAPI}
 */
export class SearchProfileResourceApi extends BaseAPI {
    /**
     * 
     * @summary Create the search profile.
     * @param {string} profileName 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchProfileResourceApi
     */
    public createSearchProfile(profileName: string, body?: string, options?: any) {
        return SearchProfileResourceApiFp(this.configuration).createSearchProfile(profileName, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the search profile.
     * @param {string} profileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchProfileResourceApi
     */
    public deleteProfile(profileName: string, options?: any) {
        return SearchProfileResourceApiFp(this.configuration).deleteProfile(profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the search profile.
     * @param {string} profileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchProfileResourceApi
     */
    public getSearchProfile(profileName: string, options?: any) {
        return SearchProfileResourceApiFp(this.configuration).getSearchProfile(profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the search profiles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchProfileResourceApi
     */
    public getSearchProfileList(options?: any) {
        return SearchProfileResourceApiFp(this.configuration).getSearchProfileList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the search profile.
     * @param {string} profileName 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchProfileResourceApi
     */
    public updateSearchProfile(profileName: string, body?: string, options?: any) {
        return SearchProfileResourceApiFp(this.configuration).updateSearchProfile(profileName, body, options).then((request) => request(this.axios, this.basePath));
    }
}
