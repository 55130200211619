/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Query Service
 * Query Service Api Documentation
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ProductDetail } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ProductDetailArray } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ProductSummaryArray } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * ProductViewResourceApi - axios parameter creator
 * @export
 */
export const ProductViewResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets products by part number.
         * @param {string} storeId 
         * @param {string} partNumber 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByAPartNumber: async (storeId: string, partNumber: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findProductByAPartNumber', 'storeId', storeId)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('findProductByAPartNumber', 'partNumber', partNumber)
            const localVarPath = `/store/{storeId}/productview/byPartNumber/{partNumber}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"partNumber"}}`, encodeURIComponent(String(partNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (associationType !== undefined) {
                localVarQueryParameter['associationType'] = associationType;
            }

            if (attributeKeyword !== undefined) {
                localVarQueryParameter['attributeKeyword'] = attributeKeyword;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (attachmentFilter !== undefined) {
                localVarQueryParameter['attachmentFilter'] = attachmentFilter;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets product details based on the product ID.
         * @param {string} storeId 
         * @param {string} productId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductById: async (storeId: string, productId: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findProductById', 'storeId', storeId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('findProductById', 'productId', productId)
            const localVarPath = `/store/{storeId}/productview/byId/{productId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (associationType !== undefined) {
                localVarQueryParameter['associationType'] = associationType;
            }

            if (attributeKeyword !== undefined) {
                localVarQueryParameter['attributeKeyword'] = attributeKeyword;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (attachmentFilter !== undefined) {
                localVarQueryParameter['attachmentFilter'] = attachmentFilter;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets products by part number.
         * @param {string} storeId 
         * @param {string} partNumber 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByPartNumber: async (storeId: string, partNumber: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findProductByPartNumber', 'storeId', storeId)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('findProductByPartNumber', 'partNumber', partNumber)
            const localVarPath = `/store/{storeId}/productview/{partNumber}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"partNumber"}}`, encodeURIComponent(String(partNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (associationType !== undefined) {
                localVarQueryParameter['associationType'] = associationType;
            }

            if (attributeKeyword !== undefined) {
                localVarQueryParameter['attributeKeyword'] = attributeKeyword;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (attachmentFilter !== undefined) {
                localVarQueryParameter['attachmentFilter'] = attachmentFilter;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets products by part numbers.
         * @param {string} storeId 
         * @param {Array<string>} partNumber 
         * @param {string} [associationType] 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {string} [contractId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByPartNumbers: async (storeId: string, partNumber: Array<string>, associationType?: string, catalogId?: string, currency?: string, langId?: string, contractId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findProductByPartNumbers', 'storeId', storeId)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('findProductByPartNumbers', 'partNumber', partNumber)
            const localVarPath = `/store/{storeId}/productview/byPartNumbers`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partNumber) {
                localVarQueryParameter['partNumber'] = partNumber;
            }

            if (associationType !== undefined) {
                localVarQueryParameter['associationType'] = associationType;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (attachmentFilter !== undefined) {
                localVarQueryParameter['attachmentFilter'] = attachmentFilter;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finds a product by its ID.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {boolean} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {string} [contractId] 
         * @param {number} [minPrice] 
         * @param {number} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {number} [searchType] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductsByCategory: async (storeId: string, categoryId: string, associationType?: string, attributeKeyword?: string, facet?: boolean, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, filterTerm?: string, manufacturer?: string, contractId?: string, minPrice?: number, maxPrice?: number, orderBy?: string, searchType?: number, checkEntitlement?: boolean, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: number, pageNumber?: number, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findProductsByCategory', 'storeId', storeId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('findProductsByCategory', 'categoryId', categoryId)
            const localVarPath = `/store/{storeId}/productview/byCategory/{categoryId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (associationType !== undefined) {
                localVarQueryParameter['associationType'] = associationType;
            }

            if (attributeKeyword !== undefined) {
                localVarQueryParameter['attributeKeyword'] = attributeKeyword;
            }

            if (facet !== undefined) {
                localVarQueryParameter['facet'] = facet;
            }

            if (facetLimit !== undefined) {
                localVarQueryParameter['facetLimit'] = facetLimit;
            }

            if (advancedFacetList !== undefined) {
                localVarQueryParameter['advancedFacetList'] = advancedFacetList;
            }

            if (filterFacet !== undefined) {
                localVarQueryParameter['filterFacet'] = filterFacet;
            }

            if (filterTerm !== undefined) {
                localVarQueryParameter['filterTerm'] = filterTerm;
            }

            if (manufacturer !== undefined) {
                localVarQueryParameter['manufacturer'] = manufacturer;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['minPrice'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['maxPrice'] = maxPrice;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (attachmentFilter !== undefined) {
                localVarQueryParameter['attachmentFilter'] = attachmentFilter;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary By default, this API returns all products under the category and subcategories by deep search. It does not only return products in the current category. There is no control over the limit of each subcategory under the category facet.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {string} [contractId] 
         * @param {string} [minPrice] 
         * @param {string} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {string} [searchType] 
         * @param {string} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {string} [pageSize] 
         * @param {string} [debug] 
         * @param {string} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductsByCategoryForAdmin: async (storeId: string, categoryId: string, associationType?: string, attributeKeyword?: string, facet?: string, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, filterTerm?: string, manufacturer?: string, contractId?: string, minPrice?: string, maxPrice?: string, orderBy?: string, searchType?: string, checkEntitlement?: string, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: string, debug?: string, pageNumber?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findProductsByCategoryForAdmin', 'storeId', storeId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('findProductsByCategoryForAdmin', 'categoryId', categoryId)
            const localVarPath = `/store/{storeId}/productview/byCategoryForAdmin/{categoryId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (associationType !== undefined) {
                localVarQueryParameter['associationType'] = associationType;
            }

            if (attributeKeyword !== undefined) {
                localVarQueryParameter['attributeKeyword'] = attributeKeyword;
            }

            if (facet !== undefined) {
                localVarQueryParameter['facet'] = facet;
            }

            if (facetLimit !== undefined) {
                localVarQueryParameter['facetLimit'] = facetLimit;
            }

            if (advancedFacetList !== undefined) {
                localVarQueryParameter['advancedFacetList'] = advancedFacetList;
            }

            if (filterFacet !== undefined) {
                localVarQueryParameter['filterFacet'] = filterFacet;
            }

            if (filterTerm !== undefined) {
                localVarQueryParameter['filterTerm'] = filterTerm;
            }

            if (manufacturer !== undefined) {
                localVarQueryParameter['manufacturer'] = manufacturer;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['minPrice'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['maxPrice'] = maxPrice;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (attachmentFilter !== undefined) {
                localVarQueryParameter['attachmentFilter'] = attachmentFilter;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (debug !== undefined) {
                localVarQueryParameter['debug'] = debug;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets product details based on the product ID.
         * @param {string} storeId 
         * @param {Array<string>} id 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductsByIds: async (storeId: string, id: Array<string>, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findProductsByIds', 'storeId', storeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProductsByIds', 'id', id)
            const localVarPath = `/store/{storeId}/productview/byIds`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (associationType !== undefined) {
                localVarQueryParameter['associationType'] = associationType;
            }

            if (attributeKeyword !== undefined) {
                localVarQueryParameter['attributeKeyword'] = attributeKeyword;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (attachmentFilter !== undefined) {
                localVarQueryParameter['attachmentFilter'] = attachmentFilter;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets product details based on a search term.
         * @param {string} storeId 
         * @param {string} searchTerm 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {boolean} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [contractId] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {number} [minPrice] 
         * @param {number} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {number} [searchType] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [categoryId] 
         * @param {string} [physicalStoreIds] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductsBySearchTerm: async (storeId: string, searchTerm: string, associationType?: string, attributeKeyword?: string, facet?: boolean, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, contractId?: string, filterTerm?: string, manufacturer?: string, minPrice?: number, maxPrice?: number, orderBy?: string, searchType?: number, checkEntitlement?: boolean, categoryId?: string, physicalStoreIds?: string, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: number, pageNumber?: number, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findProductsBySearchTerm', 'storeId', storeId)
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('findProductsBySearchTerm', 'searchTerm', searchTerm)
            const localVarPath = `/store/{storeId}/productview/bySearchTerm/{searchTerm}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"searchTerm"}}`, encodeURIComponent(String(searchTerm)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (associationType !== undefined) {
                localVarQueryParameter['associationType'] = associationType;
            }

            if (attributeKeyword !== undefined) {
                localVarQueryParameter['attributeKeyword'] = attributeKeyword;
            }

            if (facet !== undefined) {
                localVarQueryParameter['facet'] = facet;
            }

            if (facetLimit !== undefined) {
                localVarQueryParameter['facetLimit'] = facetLimit;
            }

            if (advancedFacetList !== undefined) {
                localVarQueryParameter['advancedFacetList'] = advancedFacetList;
            }

            if (filterFacet !== undefined) {
                localVarQueryParameter['filterFacet'] = filterFacet;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (filterTerm !== undefined) {
                localVarQueryParameter['filterTerm'] = filterTerm;
            }

            if (manufacturer !== undefined) {
                localVarQueryParameter['manufacturer'] = manufacturer;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['minPrice'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['maxPrice'] = maxPrice;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (physicalStoreIds !== undefined) {
                localVarQueryParameter['physicalStoreIds'] = physicalStoreIds;
            }

            if (attachmentFilter !== undefined) {
                localVarQueryParameter['attachmentFilter'] = attachmentFilter;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductViewResourceApi - functional programming interface
 * @export
 */
export const ProductViewResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductViewResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets products by part number.
         * @param {string} storeId 
         * @param {string} partNumber 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductByAPartNumber(storeId: string, partNumber: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductByAPartNumber(storeId, partNumber, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets product details based on the product ID.
         * @param {string} storeId 
         * @param {string} productId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductById(storeId: string, productId: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductById(storeId, productId, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets products by part number.
         * @param {string} storeId 
         * @param {string} partNumber 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductByPartNumber(storeId: string, partNumber: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductByPartNumber(storeId, partNumber, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets products by part numbers.
         * @param {string} storeId 
         * @param {Array<string>} partNumber 
         * @param {string} [associationType] 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {string} [contractId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductByPartNumbers(storeId: string, partNumber: Array<string>, associationType?: string, catalogId?: string, currency?: string, langId?: string, contractId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductByPartNumbers(storeId, partNumber, associationType, catalogId, currency, langId, contractId, checkEntitlement, attachmentFilter, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finds a product by its ID.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {boolean} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {string} [contractId] 
         * @param {number} [minPrice] 
         * @param {number} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {number} [searchType] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductsByCategory(storeId: string, categoryId: string, associationType?: string, attributeKeyword?: string, facet?: boolean, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, filterTerm?: string, manufacturer?: string, contractId?: string, minPrice?: number, maxPrice?: number, orderBy?: string, searchType?: number, checkEntitlement?: boolean, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: number, pageNumber?: number, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSummaryArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductsByCategory(storeId, categoryId, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, filterTerm, manufacturer, contractId, minPrice, maxPrice, orderBy, searchType, checkEntitlement, attachmentFilter, catalogId, langId, currency, pageSize, pageNumber, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary By default, this API returns all products under the category and subcategories by deep search. It does not only return products in the current category. There is no control over the limit of each subcategory under the category facet.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {string} [contractId] 
         * @param {string} [minPrice] 
         * @param {string} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {string} [searchType] 
         * @param {string} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {string} [pageSize] 
         * @param {string} [debug] 
         * @param {string} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductsByCategoryForAdmin(storeId: string, categoryId: string, associationType?: string, attributeKeyword?: string, facet?: string, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, filterTerm?: string, manufacturer?: string, contractId?: string, minPrice?: string, maxPrice?: string, orderBy?: string, searchType?: string, checkEntitlement?: string, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: string, debug?: string, pageNumber?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSummaryArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductsByCategoryForAdmin(storeId, categoryId, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, filterTerm, manufacturer, contractId, minPrice, maxPrice, orderBy, searchType, checkEntitlement, attachmentFilter, catalogId, langId, currency, pageSize, debug, pageNumber, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets product details based on the product ID.
         * @param {string} storeId 
         * @param {Array<string>} id 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductsByIds(storeId: string, id: Array<string>, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductsByIds(storeId, id, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets product details based on a search term.
         * @param {string} storeId 
         * @param {string} searchTerm 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {boolean} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [contractId] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {number} [minPrice] 
         * @param {number} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {number} [searchType] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [categoryId] 
         * @param {string} [physicalStoreIds] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductsBySearchTerm(storeId: string, searchTerm: string, associationType?: string, attributeKeyword?: string, facet?: boolean, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, contractId?: string, filterTerm?: string, manufacturer?: string, minPrice?: number, maxPrice?: number, orderBy?: string, searchType?: number, checkEntitlement?: boolean, categoryId?: string, physicalStoreIds?: string, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: number, pageNumber?: number, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSummaryArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductsBySearchTerm(storeId, searchTerm, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, contractId, filterTerm, manufacturer, minPrice, maxPrice, orderBy, searchType, checkEntitlement, categoryId, physicalStoreIds, attachmentFilter, catalogId, langId, currency, pageSize, pageNumber, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductViewResourceApi - factory interface
 * @export
 */
export const ProductViewResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductViewResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets products by part number.
         * @param {string} storeId 
         * @param {string} partNumber 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByAPartNumber(storeId: string, partNumber: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): AxiosPromise<ProductDetail> {
            return localVarFp.findProductByAPartNumber(storeId, partNumber, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets product details based on the product ID.
         * @param {string} storeId 
         * @param {string} productId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductById(storeId: string, productId: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): AxiosPromise<ProductDetail> {
            return localVarFp.findProductById(storeId, productId, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets products by part number.
         * @param {string} storeId 
         * @param {string} partNumber 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByPartNumber(storeId: string, partNumber: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): AxiosPromise<ProductDetail> {
            return localVarFp.findProductByPartNumber(storeId, partNumber, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets products by part numbers.
         * @param {string} storeId 
         * @param {Array<string>} partNumber 
         * @param {string} [associationType] 
         * @param {string} [catalogId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {string} [contractId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByPartNumbers(storeId: string, partNumber: Array<string>, associationType?: string, catalogId?: string, currency?: string, langId?: string, contractId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): AxiosPromise<ProductDetailArray> {
            return localVarFp.findProductByPartNumbers(storeId, partNumber, associationType, catalogId, currency, langId, contractId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finds a product by its ID.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {boolean} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {string} [contractId] 
         * @param {number} [minPrice] 
         * @param {number} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {number} [searchType] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductsByCategory(storeId: string, categoryId: string, associationType?: string, attributeKeyword?: string, facet?: boolean, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, filterTerm?: string, manufacturer?: string, contractId?: string, minPrice?: number, maxPrice?: number, orderBy?: string, searchType?: number, checkEntitlement?: boolean, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: number, pageNumber?: number, profileName?: string, options?: any): AxiosPromise<ProductSummaryArray> {
            return localVarFp.findProductsByCategory(storeId, categoryId, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, filterTerm, manufacturer, contractId, minPrice, maxPrice, orderBy, searchType, checkEntitlement, attachmentFilter, catalogId, langId, currency, pageSize, pageNumber, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary By default, this API returns all products under the category and subcategories by deep search. It does not only return products in the current category. There is no control over the limit of each subcategory under the category facet.
         * @param {string} storeId 
         * @param {string} categoryId 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {string} [contractId] 
         * @param {string} [minPrice] 
         * @param {string} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {string} [searchType] 
         * @param {string} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {string} [pageSize] 
         * @param {string} [debug] 
         * @param {string} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductsByCategoryForAdmin(storeId: string, categoryId: string, associationType?: string, attributeKeyword?: string, facet?: string, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, filterTerm?: string, manufacturer?: string, contractId?: string, minPrice?: string, maxPrice?: string, orderBy?: string, searchType?: string, checkEntitlement?: string, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: string, debug?: string, pageNumber?: string, profileName?: string, options?: any): AxiosPromise<ProductSummaryArray> {
            return localVarFp.findProductsByCategoryForAdmin(storeId, categoryId, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, filterTerm, manufacturer, contractId, minPrice, maxPrice, orderBy, searchType, checkEntitlement, attachmentFilter, catalogId, langId, currency, pageSize, debug, pageNumber, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets product details based on the product ID.
         * @param {string} storeId 
         * @param {Array<string>} id 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {string} [catalogId] 
         * @param {string} [contractId] 
         * @param {string} [currency] 
         * @param {string} [langId] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [attachmentFilter] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductsByIds(storeId: string, id: Array<string>, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any): AxiosPromise<ProductDetail> {
            return localVarFp.findProductsByIds(storeId, id, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets product details based on a search term.
         * @param {string} storeId 
         * @param {string} searchTerm 
         * @param {string} [associationType] 
         * @param {string} [attributeKeyword] 
         * @param {boolean} [facet] 
         * @param {string} [facetLimit] 
         * @param {string} [advancedFacetList] 
         * @param {string} [filterFacet] 
         * @param {string} [contractId] 
         * @param {string} [filterTerm] 
         * @param {string} [manufacturer] 
         * @param {number} [minPrice] 
         * @param {number} [maxPrice] 
         * @param {string} [orderBy] 
         * @param {number} [searchType] 
         * @param {boolean} [checkEntitlement] 
         * @param {string} [categoryId] 
         * @param {string} [physicalStoreIds] 
         * @param {string} [attachmentFilter] 
         * @param {string} [catalogId] 
         * @param {string} [langId] 
         * @param {string} [currency] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [profileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductsBySearchTerm(storeId: string, searchTerm: string, associationType?: string, attributeKeyword?: string, facet?: boolean, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, contractId?: string, filterTerm?: string, manufacturer?: string, minPrice?: number, maxPrice?: number, orderBy?: string, searchType?: number, checkEntitlement?: boolean, categoryId?: string, physicalStoreIds?: string, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: number, pageNumber?: number, profileName?: string, options?: any): AxiosPromise<ProductSummaryArray> {
            return localVarFp.findProductsBySearchTerm(storeId, searchTerm, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, contractId, filterTerm, manufacturer, minPrice, maxPrice, orderBy, searchType, checkEntitlement, categoryId, physicalStoreIds, attachmentFilter, catalogId, langId, currency, pageSize, pageNumber, profileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductViewResourceApi - object-oriented interface
 * @export
 * @class ProductViewResourceApi
 * @extends {BaseAPI}
 */
export class ProductViewResourceApi extends BaseAPI {
    /**
     * 
     * @summary Gets products by part number.
     * @param {string} storeId 
     * @param {string} partNumber 
     * @param {string} [associationType] 
     * @param {string} [attributeKeyword] 
     * @param {string} [catalogId] 
     * @param {string} [contractId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [attachmentFilter] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductViewResourceApi
     */
    public findProductByAPartNumber(storeId: string, partNumber: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any) {
        return ProductViewResourceApiFp(this.configuration).findProductByAPartNumber(storeId, partNumber, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets product details based on the product ID.
     * @param {string} storeId 
     * @param {string} productId 
     * @param {string} [associationType] 
     * @param {string} [attributeKeyword] 
     * @param {string} [catalogId] 
     * @param {string} [contractId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [attachmentFilter] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductViewResourceApi
     */
    public findProductById(storeId: string, productId: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any) {
        return ProductViewResourceApiFp(this.configuration).findProductById(storeId, productId, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets products by part number.
     * @param {string} storeId 
     * @param {string} partNumber 
     * @param {string} [associationType] 
     * @param {string} [attributeKeyword] 
     * @param {string} [catalogId] 
     * @param {string} [contractId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [attachmentFilter] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductViewResourceApi
     */
    public findProductByPartNumber(storeId: string, partNumber: string, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any) {
        return ProductViewResourceApiFp(this.configuration).findProductByPartNumber(storeId, partNumber, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets products by part numbers.
     * @param {string} storeId 
     * @param {Array<string>} partNumber 
     * @param {string} [associationType] 
     * @param {string} [catalogId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {string} [contractId] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [attachmentFilter] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductViewResourceApi
     */
    public findProductByPartNumbers(storeId: string, partNumber: Array<string>, associationType?: string, catalogId?: string, currency?: string, langId?: string, contractId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any) {
        return ProductViewResourceApiFp(this.configuration).findProductByPartNumbers(storeId, partNumber, associationType, catalogId, currency, langId, contractId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finds a product by its ID.
     * @param {string} storeId 
     * @param {string} categoryId 
     * @param {string} [associationType] 
     * @param {string} [attributeKeyword] 
     * @param {boolean} [facet] 
     * @param {string} [facetLimit] 
     * @param {string} [advancedFacetList] 
     * @param {string} [filterFacet] 
     * @param {string} [filterTerm] 
     * @param {string} [manufacturer] 
     * @param {string} [contractId] 
     * @param {number} [minPrice] 
     * @param {number} [maxPrice] 
     * @param {string} [orderBy] 
     * @param {number} [searchType] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [attachmentFilter] 
     * @param {string} [catalogId] 
     * @param {string} [langId] 
     * @param {string} [currency] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductViewResourceApi
     */
    public findProductsByCategory(storeId: string, categoryId: string, associationType?: string, attributeKeyword?: string, facet?: boolean, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, filterTerm?: string, manufacturer?: string, contractId?: string, minPrice?: number, maxPrice?: number, orderBy?: string, searchType?: number, checkEntitlement?: boolean, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: number, pageNumber?: number, profileName?: string, options?: any) {
        return ProductViewResourceApiFp(this.configuration).findProductsByCategory(storeId, categoryId, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, filterTerm, manufacturer, contractId, minPrice, maxPrice, orderBy, searchType, checkEntitlement, attachmentFilter, catalogId, langId, currency, pageSize, pageNumber, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary By default, this API returns all products under the category and subcategories by deep search. It does not only return products in the current category. There is no control over the limit of each subcategory under the category facet.
     * @param {string} storeId 
     * @param {string} categoryId 
     * @param {string} [associationType] 
     * @param {string} [attributeKeyword] 
     * @param {string} [facet] 
     * @param {string} [facetLimit] 
     * @param {string} [advancedFacetList] 
     * @param {string} [filterFacet] 
     * @param {string} [filterTerm] 
     * @param {string} [manufacturer] 
     * @param {string} [contractId] 
     * @param {string} [minPrice] 
     * @param {string} [maxPrice] 
     * @param {string} [orderBy] 
     * @param {string} [searchType] 
     * @param {string} [checkEntitlement] 
     * @param {string} [attachmentFilter] 
     * @param {string} [catalogId] 
     * @param {string} [langId] 
     * @param {string} [currency] 
     * @param {string} [pageSize] 
     * @param {string} [debug] 
     * @param {string} [pageNumber] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductViewResourceApi
     */
    public findProductsByCategoryForAdmin(storeId: string, categoryId: string, associationType?: string, attributeKeyword?: string, facet?: string, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, filterTerm?: string, manufacturer?: string, contractId?: string, minPrice?: string, maxPrice?: string, orderBy?: string, searchType?: string, checkEntitlement?: string, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: string, debug?: string, pageNumber?: string, profileName?: string, options?: any) {
        return ProductViewResourceApiFp(this.configuration).findProductsByCategoryForAdmin(storeId, categoryId, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, filterTerm, manufacturer, contractId, minPrice, maxPrice, orderBy, searchType, checkEntitlement, attachmentFilter, catalogId, langId, currency, pageSize, debug, pageNumber, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets product details based on the product ID.
     * @param {string} storeId 
     * @param {Array<string>} id 
     * @param {string} [associationType] 
     * @param {string} [attributeKeyword] 
     * @param {string} [catalogId] 
     * @param {string} [contractId] 
     * @param {string} [currency] 
     * @param {string} [langId] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [attachmentFilter] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductViewResourceApi
     */
    public findProductsByIds(storeId: string, id: Array<string>, associationType?: string, attributeKeyword?: string, catalogId?: string, contractId?: string, currency?: string, langId?: string, checkEntitlement?: boolean, attachmentFilter?: string, profileName?: string, options?: any) {
        return ProductViewResourceApiFp(this.configuration).findProductsByIds(storeId, id, associationType, attributeKeyword, catalogId, contractId, currency, langId, checkEntitlement, attachmentFilter, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets product details based on a search term.
     * @param {string} storeId 
     * @param {string} searchTerm 
     * @param {string} [associationType] 
     * @param {string} [attributeKeyword] 
     * @param {boolean} [facet] 
     * @param {string} [facetLimit] 
     * @param {string} [advancedFacetList] 
     * @param {string} [filterFacet] 
     * @param {string} [contractId] 
     * @param {string} [filterTerm] 
     * @param {string} [manufacturer] 
     * @param {number} [minPrice] 
     * @param {number} [maxPrice] 
     * @param {string} [orderBy] 
     * @param {number} [searchType] 
     * @param {boolean} [checkEntitlement] 
     * @param {string} [categoryId] 
     * @param {string} [physicalStoreIds] 
     * @param {string} [attachmentFilter] 
     * @param {string} [catalogId] 
     * @param {string} [langId] 
     * @param {string} [currency] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {string} [profileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductViewResourceApi
     */
    public findProductsBySearchTerm(storeId: string, searchTerm: string, associationType?: string, attributeKeyword?: string, facet?: boolean, facetLimit?: string, advancedFacetList?: string, filterFacet?: string, contractId?: string, filterTerm?: string, manufacturer?: string, minPrice?: number, maxPrice?: number, orderBy?: string, searchType?: number, checkEntitlement?: boolean, categoryId?: string, physicalStoreIds?: string, attachmentFilter?: string, catalogId?: string, langId?: string, currency?: string, pageSize?: number, pageNumber?: number, profileName?: string, options?: any) {
        return ProductViewResourceApiFp(this.configuration).findProductsBySearchTerm(storeId, searchTerm, associationType, attributeKeyword, facet, facetLimit, advancedFacetList, filterFacet, contractId, filterTerm, manufacturer, minPrice, maxPrice, orderBy, searchType, checkEntitlement, categoryId, physicalStoreIds, attachmentFilter, catalogId, langId, currency, pageSize, pageNumber, profileName, options).then((request) => request(this.axios, this.basePath));
    }
}
