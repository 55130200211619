/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Query Service
 * Query Service Api Documentation
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * SearchConfigResourceApi - axios parameter creator
 * @export
 */
export const SearchConfigResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create the configuration node in ZooKeeper.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConfiguration: async (nodeName: string, locale?: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('addConfiguration', 'nodeName', nodeName)
            const localVarPath = `/api/v2/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the configuration node data.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationData: async (nodeName: string, locale?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('getConfigurationData', 'nodeName', nodeName)
            const localVarPath = `/api/v2/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the configuration node in ZooKeeper.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColorConfiguration: async (nodeName: string, locale?: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('updateColorConfiguration', 'nodeName', nodeName)
            const localVarPath = `/api/v2/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchConfigResourceApi - functional programming interface
 * @export
 */
export const SearchConfigResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchConfigResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create the configuration node in ZooKeeper.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addConfiguration(nodeName: string, locale?: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addConfiguration(nodeName, locale, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the configuration node data.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigurationData(nodeName: string, locale?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigurationData(nodeName, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the configuration node in ZooKeeper.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateColorConfiguration(nodeName: string, locale?: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateColorConfiguration(nodeName, locale, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchConfigResourceApi - factory interface
 * @export
 */
export const SearchConfigResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchConfigResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create the configuration node in ZooKeeper.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConfiguration(nodeName: string, locale?: string, body?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.addConfiguration(nodeName, locale, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the configuration node data.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationData(nodeName: string, locale?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getConfigurationData(nodeName, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the configuration node in ZooKeeper.
         * @param {string} nodeName 
         * @param {string} [locale] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColorConfiguration(nodeName: string, locale?: string, body?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.updateColorConfiguration(nodeName, locale, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchConfigResourceApi - object-oriented interface
 * @export
 * @class SearchConfigResourceApi
 * @extends {BaseAPI}
 */
export class SearchConfigResourceApi extends BaseAPI {
    /**
     * 
     * @summary Create the configuration node in ZooKeeper.
     * @param {string} nodeName 
     * @param {string} [locale] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchConfigResourceApi
     */
    public addConfiguration(nodeName: string, locale?: string, body?: string, options?: any) {
        return SearchConfigResourceApiFp(this.configuration).addConfiguration(nodeName, locale, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the configuration node data.
     * @param {string} nodeName 
     * @param {string} [locale] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchConfigResourceApi
     */
    public getConfigurationData(nodeName: string, locale?: string, options?: any) {
        return SearchConfigResourceApiFp(this.configuration).getConfigurationData(nodeName, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the configuration node in ZooKeeper.
     * @param {string} nodeName 
     * @param {string} [locale] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchConfigResourceApi
     */
    public updateColorConfiguration(nodeName: string, locale?: string, body?: string, options?: any) {
        return SearchConfigResourceApiFp(this.configuration).updateColorConfiguration(nodeName, locale, body, options).then((request) => request(this.axios, this.basePath));
    }
}
